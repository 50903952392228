<template>
  <Page :title="$t('Help & Support')">
    <Layout>
      <LayoutSection>
        <Card :title="$t('Documentation')">
          <CardSection>
            <TextContainer>
              <div>{{$t('support.documentationHelp')}}</div>
              <a href="https://docs.fanplayr.com/portal" target="_blank">Portal Documentation</a>
            </TextContainer>
          </CardSection>
        </Card>

        <Card :title="$t('support.developerAndSupportTools')">
          <CardSection>
            <TextContainer>
              <div v-html="$t('support.supportToolsHelpHtml')"/>
              <a
                href="javascript:(function(){var api=window.fanplayr_dt;if(api){api.toggle();return;}var e=document.createElement('script');e.type='text/javascript';e.async=true;e.src='//fanplayr.s3.amazonaws.com/browser-dev-tools/loader.js';var t=document.getElementsByTagName('head')[0]||document.getElementsByTagName('body')[0];if(t){t.appendChild(e)}})()"
                class="bookmark"
              >
                <Icon icon="fas fa-bookmark" />
                {{$t('support.fanplayrTools')}}
              </a>

            </TextContainer>
          </CardSection>

          <CardSection v-if="!isProduction">
            <TextContainer>
              <div>For internal use only. This will open the Session Viewer on Stage.</div>
              <div>To add this tool to your browser, click & drag the bookmarklet below to your browser's bookmark toolbar:</div>

              <a
                href="javascript:(function()%7Btry%20%7Bvar%20key%20%3D%20fanplayr.platform.config.sessionKey%3Bif%20(!key)%20%7Balert('Fanplayr%20not%20loaded%20on%20website%20yet')%3B%7D%20else%20%7Bvar%20url%20%3D%20'https%3A%2F%2Fstage.fanplayr.com%2F%23%2FsessionViewer%2F%3Fsk%3D'%20%2B%20key%3Bwindow.open(url)%3B%7D%7D%20catch%20(ex)%20%7Balert('Fanplayr%20not%20on%20website')%3B%7D%7D)()"
                class="bookmark"
              >
                <Icon icon="fas fa-bookmark" />
                Fanplayr Stage Session
              </a>

            </TextContainer>
          </CardSection>
        </Card>

        <Card :title="$t('support.contactSupport')">
          <CardSection>
            <TextContainer>
              <div v-html="$t('support.contactSupportHelpHtml')"/>
            </TextContainer>
          </CardSection>
        </Card>
      </LayoutSection>
    </Layout>
  </Page>
</template>

<script>

export default {
  name: 'Support',
  data() {
    return {
      isProduction: false
    }
  },
  mounted() {
    this.eventHub.$emit('setNavigation')
    this.eventHub.$emit('setTitle', this.$t('Help & Support'), 'SupportLayout')
    this.eventHub.$emit('setResources')

    this.isProduction = window.location.hostname === 'portal.fanplayr.com'
  }
}
</script>

<style lang="scss" scoped>
.bookmark {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
</style>