export const emptyValuePlaceholder = '—'

export function $t(key, args) {
  const i18n = window.PortalI18n
  if (i18n) {
    return i18n.translate(key, args)
  }
  return key
}

export function getCurrentLocale() {
  const i18n = window.PortalI18n
  if (i18n) {
    return i18n.currentLocale || 'en-US'
  }
  return 'en-US'
}
