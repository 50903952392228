<template>
  <div
    :class="[
      'UIElement',
      $style.Tabs,
      theme === 'purple' && $style.Purple
    ]"
  >
    <div
      :class="$style.Tab_container"
      ref="tabContainer"
    >
      <button
        v-for="tab in tabs"
        :key="tab.id"
        type="button"
        :class="[
          $style.Tab,
          tab.disabled && $style.Tab__disabled,
          selected === tab.id && $style.Tab__selected
        ]"
        @click="onChange(tab)"
        @mouseup="$event.currentTarget.blur()"
        ref="tabs"
        :style="{
          visibility: truncatedTabIdMap[tab.id] ? 'hidden' : 'visible',
          position: truncatedTabIdMap[tab.id] ? 'absolute' : 'relative'
        }"
      >
        <div :class="$style.Tab_title">
          <slot name="tab" :tab="tab">
            {{ tab.label }}
            <Badge
              v-if="tab.badge"
              :status="tab.badgeStatus"
              :progress="tab.badgeProgress"
              :class="$style.Tab_badge"
            >{{ tab.badge }}</Badge>
          </slot>
        </div>
        <div :class="$style.Tab_bar"/>
      </button>
      <ActionList
        v-if="truncatedTabActions.length > 0"
        :items="truncatedTabActions"
        placement="bottom-end"
        :class="[
          $style.TruncatedTabsButton,
          hasSelectedTruncatedTab && $style.selected
        ]"
      >
        <Button
          type="plain"
          :icon="truncatedTabLabel ? '' : 'fas fa-ellipsis-h'"
          :dropdown="!!truncatedTabLabel"
        >{{truncatedTabLabel}}</Button>
      </ActionList>
    </div>
  </div>
</template>

<script>
import {ActionList} from '../ActionList'
import {Badge} from '../Badge'
import {debounce} from '../../utils'

export default {
  components: {
    ActionList,
    Badge
},
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    /**
     * The selected tab ID.
     */
    selected: {
      type: String
    },
    /**
     * ```ts
     * interface Tab {
     *   id: string
     *   label: string
     *   disabled?: boolean
     *   badge?: string
     * }
     * ```
     */
    tabs: {
      type: Array,
      required: true
    },
    // blue or purple?
    theme: {
      type:String,
      default: 'blue'
    }
  },
  data() {
    return {
      // A map of tab IDs that are truncated
      truncatedTabIdMap: {}
    }
  },
  computed: {
    truncatedTabs() {
      return this.tabs.filter(tab => this.truncatedTabIdMap[tab.id])
    },
    truncatedTabActions() {
      return this.truncatedTabs.map(tab => {
        return {
          label: tab.label,
          icon: tab.id === this.selected ? 'fa fa-check' : null,
          disabled: tab.disabled,
          onAction: () => this.onChange(tab)
        }
      })
    },
    hasSelectedTruncatedTab() {
      return this.truncatedTabs.some(tab => tab.id === this.selected)
    },
    truncatedTabLabel() {
      const selectedTab = this.tabs.find(tab => tab.id === this.selected)
      return this.hasSelectedTruncatedTab
        ? selectedTab.label
        : ''
    }
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(debounce(this.resizeTabs, 100, {
      leading: true,
      trailing: true
    }))
    this.resizeObserver.observe(this.$refs.tabContainer)
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
  },
  methods: {
    onChange(tab) {
      if (tab.disabled) return
      /**
       * Emitted when the tab changes. The first argument is the tab ID and the
       * second argument is the selected tab object.
       *
       * @event change
       * @type {string, Tab}
       */
      this.$emit('change', tab.id, tab)
    },
    resizeTabs() {
      // A map of all tab IDs and whether they should be hidden
      const truncatedTabIdMap = {}
      const tabsContainerWidth = this.$refs.tabContainer.getBoundingClientRect().width
      // Start with an initial width to compensate for the truncated button that
      // might need to be shown.
      let currentWidth = 150
      this.$refs.tabs.forEach((tabEl, index) => {
        const tab = this.tabs[index]
        const width = tabEl.offsetWidth
        currentWidth += width
        truncatedTabIdMap[tab.id] = currentWidth >= tabsContainerWidth
      })
      this.truncatedTabIdMap = truncatedTabIdMap
    }
  }
}
</script>

<style lang="scss" module>
@import "../../styles/variables";
$textColor: #212b36;

.Purple {
  .Tab_container {
    box-shadow: inset 0 -1px 0 #CACACA;
  }
  .Tab {
    position: relative;
  }
  .Tab:not(.Tab__disabled) {
    &:focus {
      box-shadow:
        inset 0 0 1px 0 #28235F,
        0 0 1px 0 #28235F;
    }
    &:hover .Tab_title {
      border-color: transparent;
      color: #28235F;
    }
  }
  .Tab_title {
    // border-bottom: 3px solid transparent;
    color: #7772B1;
    padding: 16px 16px 8px;
  }
  .Tab_bar {
    position: absolute;
    bottom: -2px;
    width: 100%;
    height: 5px;
    background: #28235F;
    border-radius: 5px;
  }
  .Tab__selected, .Tab__selected:hover {
    .Tab_title {
      border-color: transparent;
      color: #28235F;
      cursor: default;
    }
    .Tab_bar {
      display: block;
    }
  }
  .TruncatedTabsButton {
    button {
      color: #7772B1;
      &:hover {
        color: #28235F;
      }
    }
    &.selected {
      .Tab_bar {
        display: block;
      }
      button {
        color: #28235F;
      }
      border-color: #28235F;
    }
  }

}

.Tab_container {
  display: flex;
  box-shadow: inset 0 -1px 0 #e0e3e9;
}
.Tab_bar {
  display: none;
}

.Tab {
  appearance: none;
  padding: 0;
  border: none;
  background: none;
  font-size: inherit;

  &:active {
    outline: none;
  }

  &:not(.Tab__disabled):focus {
    box-shadow:
      inset 0 0 2px 0 $focusColor,
      0 0 2px 0 $focusColor;
    outline: none;
  }

  &:not(.Tab__disabled, .Tab__selected):hover .Tab_title {
    border-color: #dfe3e8;
    color: #212b36;
  }
}
.Tab:not(.Tab__disabled) {
  .Tab_title {
    cursor: pointer;
  }
}

.Tab_title {
  display: block;
  padding: 16px 16px 13px;
  border-bottom: 3px solid transparent;
  color: #637381;
  line-height: 20px;
  white-space: nowrap;

  .Tab__selected &, .Tab__selected:hover & {
    border-color: $focusColor;
    color: #212b36;
    cursor: default;
  }
}

.Tab_badge {
  position: relative;
  top: -1px;
  margin-left: 5px;
  line-height: 15px;
}

.TruncatedTabsButton {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0 16px;
  border-bottom: 3px solid transparent;
  &.selected {
    border-color: $focusColor;
  }
}
</style>
