import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import objectSupport from 'dayjs/plugin/objectSupport'
import isoWeek from 'dayjs/plugin/isoWeek'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
dayjs.extend(quarterOfYear)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(LocalizedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(objectSupport)
dayjs.extend(isoWeek)


// TODO: can all this be put in a function or use PortalI18n??
import 'dayjs/locale/ja'
import 'dayjs/locale/es'
import 'dayjs/locale/it'
import 'dayjs/locale/fr'
import 'dayjs/locale/pt'
import 'dayjs/locale/de'
import 'dayjs/locale/nl'
const supportedLangs = [
  'en',
  'ja',
  'es',
  'it',
  'fr',
  'pt',
  'de',
  'nl'
]

function setLocale(locale:string) {
  const [lang] = locale.split('-')
  if (!supportedLangs.includes(lang)) {
    locale = 'en-US'
  }
  dayjs.locale(locale)
}


const mysqlFormat = 'YYYY-MM-DD HH:mm:ss'

function formatNowUtc(format?:string):string {
  return dayjs.utc().format(format || mysqlFormat)
}

function formatDate(date:string|dayjs.Dayjs, format?:string):string {
  return dayjs(date).format(format || mysqlFormat)
}

function dateIsValid(date:string, format?:string):boolean {
  return dayjs(date, format || mysqlFormat, true).isValid()
}

export {
  formatNowUtc,
  formatDate,
  dateIsValid,
  dayjs,
  setLocale,
  mysqlFormat
}