<template>
  <div :class="[
    'UIElement',
    overflowVisible && $style.overflowVisible
  ]">
    <EventDelegator
      v-if="visible && $listeners.close"
      @keydown.esc="close"
    />
    <transition
      :enter-active-class="$style.backdropEnterActive"
      :leave-active-class="$style.backdropLeaveActive"
      :enter-class="$style.backdropEnter"
      :leave-to-class="$style.backdropLeaveTo"
    >
      <div :class="$style.backdrop" v-show="visible"></div>
    </transition>
    <transition
      :enter-active-class="$style.dialogEnterActive"
      :leave-active-class="$style.dialogLeaveActive"
      :enter-class="$style.dialogEnter"
      :leave-to-class="$style.dialogLeaveTo"
      @enter="enter"
      @after-enter="afterEnter"
      @after-leave="afterLeave"
    >
      <div :class="$style.dialog" :style="dialogStyle" v-show="visible">
        <div v-if="loading" :class="$style.loader">
          <Spinner color="blue" size="large" />
        </div>

        <div
          v-if="!noHeader"
          :class="$style.header"
          ref="header"
        >
          <div :class="$style.title">{{title}}</div>
          <Button
            v-if="$listeners.close"
            :class="$style.close"
            type="plain"
            icon="fal fa-times"
            @click="close"
          />
        </div>
        <div
          v-if="$slots.preBody"
          :class="$style.preBody"
          :style="preBodyStyle"
          ref="preBody"
        >
          <!-- @slot Pre-body content, appears between header and body. -->
          <slot name="preBody"></slot>
        </div>
        <div
          :class="$style.body"
          :style="[
            {maxHeight: bodyMaxHeight},
            bodyStyle,
          ]"
        >
          <!-- @slot Body content, appears between header and footer. -->
          <slot></slot>
        </div>
        <div
          v-if="actions.length || $slots.footer"
          :class="$style.footer"
          ref="footer"
        >
          <!-- @slot Footer. -->
          <slot v-if="$slots.footer" name="footer"></slot>
          <template v-else>
            <div :class="$style.footerWrapper">
              <div :class="$style.footerWrapperLeft">
                <ButtonGroup>
                  <Button
                    v-for="(action, index) in tertiaryActions"
                    :key="index"
                    :icon="action.icon"
                    :type="action.type"
                    :disabled="action.disabled"
                    :url="action.url"
                    :external="action.external"
                    :tooltip="action.tooltip"
                    :tooltipPosition="action.tooltipPosition"
                    @click="action.onAction"
                  >{{action.label}}</Button>
                </ButtonGroup>
              </div>
              <div :class="$style.footerWrapperRight">
                <ButtonGroup>
                  <Button
                    v-for="(action, index) in actions"
                    :key="index"
                    :icon="action.icon"
                    :type="action.type"
                    :disabled="action.disabled"
                    :url="action.url"
                    :external="action.external"
                    :tooltip="action.tooltip"
                    :tooltipPosition="action.tooltipPosition"
                    @click="action.onAction"
                  >{{action.label}}</Button>
                </ButtonGroup>
              </div>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {Button, ButtonGroup} from '../Button'
import {EventDelegator} from '../EventDelegator'
import {Spinner} from '../Spinner'

export default {
  name: 'Modal',
  components: {
    Button,
    ButtonGroup,
    EventDelegator,
    Spinner
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    title: {
      type: String
    },
    /**
     * Options for the primary action button.
     *
     * ```ts
     * interface Action {
     *   label: string
     *   // Defaults to "primary". See `Button` type.
     *   type: string
     *   // See &lt;Icon&gt; component.
     *   icon: string
     *   disabled: boolean
     *   url: string
     *   external: boolean
     *   onAction(): void
     *   tooltip: string
     *   tooltipPosition: string
     * }
     * ```
     */
    primaryAction: {
      type: Object
    },
    /**
     * An array of options for the secondary actions.
     *
     * ```ts
     * interface Action {
     *   label: string
     *   // Defaults to "default". See `Button` type.
     *   type: string
     *   // See &lt;Icon&gt; component.
     *   icon: string
     *   disabled: boolean
     *   url: string
     *   external: boolean
     *   onAction(): void
     *   tooltip: string
     *   tooltipPosition: string
     * }
     * ```
     */
    secondaryActions: {
      type: Array
    },
    /**
     * An array of options for the tertiary actions. These will show on the left side of the footer.
     *
     * ```ts
     * interface Action {
     *   label: string
     *   // Defaults to "default". See `Button` type.
     *   type: string
     *   // See &lt;Icon&gt; component.
     *   icon: string
     *   disabled: boolean
     *   url: string
     *   external: boolean
     *   onAction(): void
     *   tooltip: string
     *   tooltipPosition: string
     * }
     * ```
     */
     tertiaryActions: {
      type: Array
    },
    /**
     * Shows a spinner over the whole modal dialog and prevents closing.
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * Does not render header section
     */
    noHeader: {
      type: Boolean,
      default: false
    },
    /**
     * Style to add to the dialog element, e.g. to set a width, height
     */
     dialogStyle: {
      type: Object
    },
    /**
     * Styles for the pre-body slot.
     */
    preBodyStyle: {
      type: Object
    },
    /**
     * Styles for the body slot.
     */
    bodyStyle: {
      type: Object
    },
    /**
     * Workaround to allow modal body overflow to be visible.
     * Used to prevent popper items that do not use teleport within the modal from being clipped.
     */
    overflowVisible: {
      type: Boolean
    }
  },
  data() {
    return {
      bodyMaxHeight: null
    }
  },
  computed: {
    actions() {
      const actions = []
      if (this.secondaryActions) {
        actions.push(...this.secondaryActions)
      }
      if (this.primaryAction) {
        actions.push({
          type: 'primary',
          ...this.primaryAction
        })
      }
      return actions
    }
  },
  methods: {
    enter() {
      this.updateBodyMaxHeight()
    },
    afterEnter() {
      /**
       * Emitted after the modal is shown.
       */
      this.$emit('afterEnter')
    },
    afterLeave() {
      /**
       * Emitted after the modal is hidden.
       */
      this.$emit('afterLeave')
    },
    close() {
      /**
       * Emitted when the model's "X" button is clicked.
       *
       * @event close
       */
      this.$emit('close')
    },
    updateBodyMaxHeight() {
      const {header, preBody, footer} = this.$refs
      const fixedHeight =
        ((header && header.clientHeight) || 0) +
        ((preBody && preBody.clientHeight) || 0) +
        ((footer && footer.clientHeight) || 0)
      // Screen height - dialog margin - fixed height
      this.bodyMaxHeight = `calc(100vh - 200px - ${fixedHeight}px)`
    }
  }
}
</script>

<style lang="scss" module>
.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background: #000;
  opacity: 0.32;
  will-change: opacity;
}

.dialog {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  width: 620px;
  max-width: calc(100vw - 32px);
  margin: 100px auto 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 31px 41px 0 rgba(32, 42, 53, 0.2),
    0 2px 16px 0 rgba(32, 42, 54, 0.08);
  transform: translate(-50%, 0);
  overflow: hidden;

  .overflowVisible & {
    overflow: visible;
  }
}
.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
}
.header {
  display: flex;
  align-items: center;
  min-height: 60px;
  border-bottom: 1px solid #dfe3e8;
}
.title {
  flex: 1 1 auto;
  padding: 0 20px;
  color: #212b36;
  font-size: 20px;
  line-height: 28px;
}
.close {
  margin-right: 10px;
  color: #637381;
  font-size: 25px;
}

.preBody {
  padding: 20px;
  padding-bottom: 0;
}

.body {
  padding: 20px;
  overflow: auto;

  .overflowVisible & {
    overflow: visible;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 18px;
  border-top: 1px solid #dfe3e8;

  .footerWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;

    .footerWrapperLeft {
      flex: 1 1 auto;
    }
    .footerWrapperRight {
      flex: none;
    }
  }
}

.backdropEnterActive,
.backdrioLeaveActive {
  transition: opacity 0.5s ease;
}
.backdropEnter,
.backdropLeaveTo {
  opacity: 0;
}

.dialogEnterActive,
.dialogLeaveActive {
  transition-property: transform, opacity;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.dialogEnter,
.dialogLeaveTo {
  transform: translate(-50%, 200px);
  opacity: 0;
}
</style>
